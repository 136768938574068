import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
import { TextField } from '@material-ui/core';
import ReactGA from 'react-ga';

//language file
import { translate } from '../../i18n/translate';
import { LOCALES } from '../../i18n';


//buttons
// import pinFormPopup from '../../images/question.png';
import pinFormPopupImage from '../../images/barcode.en.v2.png';
import pinFormPopupImagefr from '../../images/barcode.fr.png';
// import closeBtn from '../../images/close black.png';
import { NavLink } from 'react-router-dom';

class PinForm extends Component{

    state = {
        // buttonHover : false,
        // popupVisible: false,
        pinError: ''
    }

    //Handle Mouse over event for enter now button
    // mouseEnter = () =>{
    //     this.setState(prevState =>{
    //         return {buttonHover: !prevState.buttonHover}
    //     })
    // }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    // mouseLeave = () =>{
    //     this.setState(prevState =>{
    //         return {buttonHover: !prevState.buttonHover}
    //     })
    // }

    /*
    //pin form help popup
    popupClickHandle = () => {
        this.setState({
            popupVisible: true
        })
    }

    //popup close button click
    popupCloseButtonHandle = () => {
        this.setState({
            popupVisible: false
        })
    } */

    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        const form = this.form;

        // gsap.from(form, .6, {
        //     opacity:1,
        //     x: -300,
        //     ease: Power3.easeOut
        // })
    }

    render(){

        const { values, nextStep, handleChange, inputPinClickHandler, errorMessage, inputKeypressHandler, lang } = this.props;
        const errorNum = parseInt(errorMessage.split('|')[0]);
        const errorMess = errorMessage.split('|')[1];
        const error1 = errorNum===1 || errorNum===5 || errorNum===10 || errorNum===14;
        const error2 = errorNum===4 || errorNum===5 || errorNum===13 || errorNum===14;
        const error3 = errorNum===9 || errorNum===10 || errorNum===13 || errorNum===14; 

        return(
            <Fragment> 
                <div className="main-wrapper bg-left">
                    <div className="container pin-form__container">
                        <p className="steps">{translate('step1')}</p>
                        <h1 className="form__heading pin-form__heading">{translate('enterPin')}</h1>
                        <img src={lang===LOCALES.ENGLISH?pinFormPopupImage:pinFormPopupImagefr} alt="Pin form popup figure"/>
                        <p className="form__description pin-form__description">{translate('enterKeyDescription')}<NavLink to="/products">{translate('enterKeyLink')}</NavLink><small>{translate('enterKeySmall')}</small></p>
                        <div className="mui-container pin-form" ref={el => this.form = el}>
                            <form onSubmit={nextStep}>

                                <TextField className={error1 ? 'error' : ''} variant="filled" required label={translate('pin')} defaultValue={values.sku1} onChange={handleChange('sku1')} onClick={inputPinClickHandler} onKeyPress={inputKeypressHandler} />

                                <TextField className={error2 ? 'error' : ''} variant="filled" required label={translate('pin')} defaultValue={values.sku2} onChange={handleChange('sku2')} onClick={inputPinClickHandler} onKeyPress={inputKeypressHandler} />

                                <TextField className={error3 ? 'error' : ''} variant="filled" required label={translate('pin')} defaultValue={values.sku3} onChange={handleChange('sku3')} onClick={inputPinClickHandler} onKeyPress={inputKeypressHandler} />

                                <p className="pinForm__errorMessage">{errorMess}</p>

{/* todo. this should say try again if it's too many entries/day/ip or whatever */}
                                <button type="submit" className="pin-form__submit-btn green-btn" onClick={nextStep} >
                                    <p>{translate('nextStep')}</p>
                                </button>
                            </form>
                        </div>

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default PinForm;