import React, { Component, Fragment } from 'react';
import { TextField } from '@material-ui/core';
import ReactGA from 'react-ga';
import STQForm from '../forms/STQForm';
import AddressForm from '../forms/AddressForm';
import axios from 'axios';

//translate
import { translate, translatedlink } from '../../i18n/translate';

//images
import prize from '../../images/cooler.png';

//css
import '../../styles/pages/_you-won.scss';

class YouWon extends Component{

    state = {
        confirmed: 0
    }

    correctAnswer = () => {
        const { timestamp } = this.props;
        console.log('you won - correct');
        ReactGA.pageview(window.location.pathname + '?status=stq-correct');
        this.setState({confirmed:1});
        window.scrollTo(0, 0);
        // send call to backend
        // axios.post('https://xbox-mondelez-contest.herokuapp.com/confirmation', 
        axios.post(process.env.REACT_APP_BACKEND_URL+'/confirmation', 
        { id:timestamp, correctanswer:true },
        { headers: { 'Content-Type': 'application/json' } });
    }
    incorrectAnswer = () => {
        const { timestamp } = this.props;
        console.log('you won - incorrect');
        ReactGA.pageview(window.location.pathname + '?status=stq-incorrect');
        this.setState({confirmed:-1});
        window.scrollTo(0, 0);
        // send call to backend
        // axios.post('https://xbox-mondelez-contest.herokuapp.com/confirmation', 
        axios.post(process.env.REACT_APP_BACKEND_URL+'/confirmation', 
        { id:timestamp, correctanswer:false },
        { headers: { 'Content-Type': 'application/json' } });
    }
    tryAgain = (e) => {
        e.preventDefault();
        window.location.reload()
    }
    submitAddress = vals => {
        
        const { timestamp } = this.props;
        const { error, complete, valid, ...data } = vals;
        console.log('submit',{ timestamp, ...data });

        axios.post(process.env.REACT_APP_BACKEND_URL+'/updatewinner', 
        { id:timestamp, ...data },
        { headers: { 'Content-Type': 'application/json' } }); // todo. catch errors?

        ReactGA.pageview(window.location.pathname + '?status=address-submitted');
        this.setState({confirmed:2});
        window.scrollTo(0, 0);
    }

    componentDidMount(){
        console.log('you won - cdm');
        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    // handleChange = (e) => {
    //     this.setState({ val: e.target.value});
    // }

    render(){
        
        // const currentPage = window.location.href;
        const { confirmed } = this.state;

        return(
            <Fragment>

                <div className="main-wrapper bg-left">
                    <section>
                        <div className="container win">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    {confirmed === 2 &&
                                    // you win! finished
                                        <div className="you-win">
                                            <h1>{translate('winningPage.heading4', {br: <br />})}</h1>
                                            <h3>{translate('winningPage.body4')}</h3>
                                            <p className="legal">{translate('winningPage.legal', {br: <br />})}</p>
                                        </div>
                                    }
                                    {confirmed === 1 &&
                                    // you win! enter your address.
                                        <div className="you-win">
                                            <h1>{translate('winningPage.heading1', {br: <br />})}</h1>
                                            <h3>{translate('winningPage.heading2')}</h3>
                                            <img src={prize} alt="Game Pass"/>
                                            <p>
                                                {translate('winningPage.body2', {br: <br />})}
                                            </p>
                                            <AddressForm onSubmit={this.submitAddress} />
                                            <p className="legal">{translate('winningPage.legal', {br: <br />})}</p>
                                        </div>
                                    }
                                    {confirmed === 0 &&                                     
                                    // STQ form
                                        <div className="you-win stq-form-container">
                                            <h1>{translate('congratulation', {br: <br />})}</h1>
                                            <h3>{translate('winningPage.heading')}</h3>
                                            <p>
                                                {translate('winningPage.body', {br: <br />})}
                                            </p>
                                           
                                            <STQForm nextCorrect={this.correctAnswer} nextIncorrect={this.incorrectAnswer} />
                                            {/* <p className="legal">{translate('winningPage.legal', {br: <br />})}</p> */}
                                        </div>
                                    }
                                    {confirmed === -1 && 
                                    // incorrect STQ
                                        <div className="you-win lose">
                                        <h1>{translate('winningPage.heading3', {br: <br />})}</h1>
                                        <h3>
                                            {translate('winningPage.body3', {br: <br />})}
                                        </h3>

                                        <button className="green-btn" onClick={this.tryAgain}>{translate('tryAgain.btn')}</button>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div> 
            </Fragment>
        );
    }
}

export default YouWon;