import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
// import Cookie from 'js-cookie';
import Recaptcha from 'react-google-invisible-recaptcha';
import PinForm from '../forms/PinForm';
import InfoForm from '../forms/InfoForm';
import YouWon from './YouWon';
import YouLose from './YouLose';
// import ReactGA from 'react-ga'; //google analytics

//import css
import '../../styles/pages/_enter-contest.scss';

const RECAPTCHA_SITE_KEY = '6Lcrmk0mAAAAAB8KIGoYFRRDf4GBwcR96YfzqHKs';

class EnterContest extends Component{

    state = {
        step: 1,
        sku1 : "",
        sku2 : "",
        sku3 : "",
        fName : "",
        lName : "",
        email: "",
        phone: "",
        age: false,
        rules: false,
        optin: false,
        pinFormActive: true,
        loading: false,
        reCapToken: "",
        processing: false,

        fNameError: "",
        lNameError: "",
        emailError: "",
        ageError: "",
        rulesError: "",
        optinError: "",
        skuError: "",

        timestamp: ""
    }

    //go to next step
    nextStep = (e) => {
        e.preventDefault();
        const { step, pin } = this.state;
        
        if (pin !== ""){
            this.setState({
                step: step + 1
            })
        }
    }

    //go back
    prevState = () => {
        //const { step } = this.state;
        this.setState({
            step: 1
        })
    }

    //input fields on change or on type
    handleChange = input => e => {
        this.setState({
            [input] : e.target.value
        })

        //setting error to be blank once start typing
        const errName = input.indexOf('sku')>-1 ? 'sku' : input;
        const errorState = `${errName}Error`;
        this.setState({
            [errorState] : ""
        })
    }

    //check box on click
    handleCheckbox = e => {
        this.setState({
            [e.target.name] : e.target.checked
        })

        ////setting error to be blank once clicked
        const errorState = e.target.name + 'Error';
        this.setState({
            [errorState] : ""
        })
    }

    //close warning from PinForm 
    inputPinClickHandler = () => {
        this.setState({
            step: 2
        })
    }

    // PIN input field onkeypress 
    inputKeypressHandler = () =>{
        if (this.state.step !== 2){
            this.setState({
                step: 2
            })
            console.log('step update');
        }
    }

    //error popup modal close on info form
    closeWarningfromInfoForm = () =>{
        this.setState({
            step: 2
        })
    }


    //validate form
    validateForm = () => {
        console.log('validation is called')
        const { fName, lName, email, phone, age, rules, optin } = this.state;
        let fNameError = "";
        let lNameError = "";
        let emailError = "";
        let phoneError = "";
        let ageError = "";
        let rulesError = "";
        let optinError = "";

        //getting language cookei
        const langPreferance = this.props.lang;

        if (langPreferance === 'fr-CA'){
            fName ? fNameError = "" : fNameError = "Entrez votre prénom s'il vous plait";
            lName ? lNameError = "" : lNameError = "Veuillez entrer votre nom de famille";
            age ? ageError = "" : ageError = "Veuillez confirmer que vous avez l'âge de la majorité dans la province ou le territoire où vous résidez";
            phone ? phoneError = "" : phoneError = "Veuillez saisir un numéro de téléphone valide";
            rules ? rulesError = "" : rulesError = "Veuillez confirmer que vous avez lu et respecté les règles et règlements du concours et la politique de confidentialité de Mondelēz Canada";
            optin ? optinError = "" : optinError = "Veuillez confirmer que vous consentez";
            if(email && email.includes('@') && email.includes('.') ) {emailError = "";} else {  emailError = "Veuillez fournir une adresse email valide"; }

        } else{
            fName ? fNameError = "" : fNameError = "Please enter your first name";
            lName ? lNameError = "" : lNameError = "Please enter your last name";
            age ? ageError = "" : ageError = "Please confirm that you are the age of majority in the province/territory where you reside";
            phone ? phoneError = "" : phoneError = "Please enter a valid phone number";
            rules ? rulesError = "" : rulesError = "Please confirm that you have read and comply with the Contest rules and regulations and the Mondelēz Canada Privacy Policy";
            optin ? optinError = "" : optinError = "Please confirm that you consent";
            if(email && email.includes('@') && email.includes('.') ) {emailError = ""; } else {  emailError = "Please provide a valid email address"; }
        }

        if (fNameError || lNameError || emailError || phoneError || ageError || rulesError || optinError){
            this.setState({
                fNameError,
                lNameError,
                emailError,
                phoneError,
                ageError,
                rulesError,
                optinError
            })
            return false;
        }

        return true;
    }

    validateSkus = () => {
        console.log('validate skus');
        const { sku1, sku2, sku3 } = this.state;
        let skuError = '';

        const langPreferance = this.props.lang;

        if (!sku1 || !sku2 || !sku3) { // missing.
            let skuCode = 0;
            if (!sku1) skuCode += 1;   // 1  / 0  / 0 / 1  /
            if (!sku2) skuCode += 2*2; // 5  / 4  / 0 / 1  / 
            if (!sku3) skuCode += 3*3; // 14 / 13 / 9 / 10 /
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                    'Veuillez saisir les 3 CUP' :
                    'Please enter all 3 UPCs';
            this.setState({skuError});
            console.log(skuError);
            return false;
        } else if (sku1.length!==11 || sku2.length!==11 || sku3.length!==11) { // too short.
            let skuCode = 0;
            if (sku1.length!==11) skuCode += 1;   // 1  / 0  / 0 / 1  /
            if (sku2.length!==11) skuCode += 2*2; // 5  / 4  / 0 / 1  / 
            if (sku3.length!==11) skuCode += 3*3; // 14 / 13 / 9 / 10 /
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                    'Veuillez saisir 3 CUP valides' :
                    'Please enter 3 valid UPCs';
            this.setState({skuError});
            console.log(skuError);
            return false;
        } else if (sku1===sku2 || sku1===sku3 || sku2===sku3) { // not unique
            let skuCode = 0;
            if (sku1===sku2) skuCode += 1 + 4; 
            if (sku1===sku3) skuCode += 1 + 9; 
            if (sku2===sku3) skuCode += 4 + 9; 
            if (sku1===sku2 && sku2===sku3) skuCode = 1 + 4 + 9;
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                    'Veuillez saisir 3 CUP uniques' :
                    'Please enter 3 unique UPCs';
            this.setState({skuError});
            console.log(skuError);
            return false;
        } else {
            return true;
        }
    }
    setSkuError = code => {
        let skuCode = 0;
        let skuError = '';
        const langPreferance = this.props.lang;
            
        if ( code === 403 ){ // code used
            skuCode = 14;
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                    'Vous avez déjà participé au concours avec cette combinaison de CUP' :
                    'You have already entered the contest with this combination of UPCs';
        } else if ( code > 700 && code < 799 ) { // code not found
            skuCode = code - 700; // deterimine which code is not found
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                'Une ou plusieurs de vos CUP est(sont) invalide(s). Veuillez vérifier les chiffres et réessayer.' :
                'One or more of your UPCs is invalid. Please check the numbers and try again.';
            console.log(skuError);
        } else if( code === 406 ){ // code invalid
            skuCode = 14; // deterimine which code is not found
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                'Une ou plusieurs de vos CUP est(sont) invalide(s). Veuillez vérifier les chiffres et réessayer.' :
                'One or more of your UPCs is invalid. Please check the numbers and try again.';
        } else if ( code === 429 ) { // too many requests - ip
            skuCode = 0;
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                'Trop de demandes de participation à partir de la même adresse IP.' :
                'Too many requests from the same IP';
        } else if ( code === 451 ) { // too many requests - email
            skuCode = 0;
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                'Vous avez atteint le nombre maximal de participations par jour. Veuillez réessayer demain.' : 
                'You\'ve reached the maximum number of entries per day. Please try again tomorrow.';
        } else if ( code === 452 ) { // too many requests - email
            skuCode = 0;
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                'Vous avez atteint le nombre maximal de participations au concours.' : 
                'You\'ve reached the maximum number of entries for the contest.';
        } else if ( code === 500 ) { // internal error
            skuCode = 0;
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                'Désolé. Une erreur s’est produite. Essayez à nouveau.' : 
                'Sorry, there was an error on our side. Please try again.';
        } else if ( code === 503 ) { // database error
            skuCode = 0;
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                'Le serveur est actuellement en maintenance. Essayez à nouveau.' : 
                'The server is currently undergoing maintenance. Please try again later.';
        } else if ( code === 400 ) { // bad request
            skuCode = 0;
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                'Désolé. Une erreur s’est produite. Essayez à nouveau.' : 
                'Sorry, there was an error on our side. Please try again.';
        } else if ( code === 401 ) { // recaptcha error
            skuCode = 0;
            skuError = skuCode + '|';
            skuError += langPreferance==='fr-CA' ? 
                'Veuillez vérifier que vous n’êtes pas un robot.' : 
                'Please verify that you are not a robot.';
        }

        this.setState({skuError});
    }

    //handle recaptcha
    handleSubmitInfo = e => {
        e.preventDefault();
        const validateForm= this.validateForm();
        console.log('before validation', validateForm)
        if (validateForm === true){
            this.setState({ step: 2 })
            window.scrollTo(0, 0);
        }
    }

    verifyCallback = res =>{ 
        // todo. why isn't this being called?
        // possibly causing a Timeout error?
        console.log('verify captcha callback',res);
    }

    handleSubmitSku = e => {
        e.preventDefault();
        const { processing } = this.state;
        if (processing) {
            console.log('processing'); return;
        }
        const recaptcha = this.recaptcha;
        const validSkus = this.validateSkus();
        
        if (validSkus) {
            this.setState({processing:true});
            recaptcha.execute().then(token => {
                console.log(token)
                this.setState({ reCapToken: token })
                this.handleSubmit(); 
            });
        }
    }

    //handle form submit
    handleSubmit = e => {
        //e.preventDefault();
        console.log('after submission')

        const { sku1, sku2, sku3, fName, lName , phone, email, age, rules, optin, reCapToken } = this.state;

            //loading state on
            this.setState({loading: true})

            // axios.post('https://xbox-mondelez-contest.herokuapp.com/entries', {
           axios.post(process.env.REACT_APP_BACKEND_URL+'/entries', {
                "email": email,
                "firstname": fName,
                "lastname": lName,
                "phone": phone,
                "age": age,
                "rules": rules,
                "optin": optin,
                "sku":sku1+'/'+sku2+'/'+sku3,
                "token" : reCapToken,
                "lang" : this.props.lang
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({loading: false,processing:false})
                console.log('res', res)
                if ( res.status === 200 ){
                    this.setState({ step: 3, timestamp: res.data.timestamp }, () => { this.props.history.push(`?status=winner`); window.scrollTo(0, 0); })
                } else if ( res.status === 202 ){
                    this.setState({ step: 4 }, () => { window.scrollTo(0, 0); })
                } 
            }).catch(err => {
                const { step } = this.state;
                this.setState({loading: false,processing:false})
                //console.log('currently on step',step);
                if (step===3||step===4) return;
                console.log('Error', err.response)
                if ( err.response.status === 411 ) { // missing fields
                    this.setState({ step: 8 })
                } else if( err.response.status === 412 ){ // invalid email
                    this.setState({emailError : "Please enter a valid email", step: 1})
                } else if ( err.response.status === 413 ) { // invalid email
                    this.setState({phoneError : "Please enter a valid phone number", step: 1})
                } else { // catchall
                    this.setSkuError( err.response.status );
                }
            })
    }

    componentDidMount(){
        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render(){

        const { step, loading } = this.state;
        const { sku1, sku2, sku3, fName, lName , phone, email, age, rules, optin, timestamp } = this.state;
        const values = { sku1, sku2, sku3, fName, lName, phone, email, age, rules, optin };
        const { fNameError, lNameError, emailError, phoneError, ageError, rulesError, optinError, skuError } = this.state;
        const errorMessages = { fNameError, lNameError, emailError, phoneError, ageError, rulesError, optinError, skuError };
        const langPreferance = this.props.lang;

        switch(step){
            default: 
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )

            case 2:
                return(
                    <Fragment>
                        <PinForm values={values} nextStep={this.handleSubmitSku} handleChange={this.handleChange} lang={langPreferance} errorMessage={skuError} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
                    </Fragment>
                );

            case 3:
                return(
                    <YouWon timestamp={timestamp} />
                )

            case 4:
                return(
                    <YouLose lang={langPreferance} />
                )

            case 5: 
                return(
                    <Fragment>
                        <PinForm values={values} nextStep={this.handleSubmitSku} handleChange={this.handleChange} errorMessage={langPreferance === 'fr-CA' ? `Code non valide. Le code inscrit ne doit pas déjà avoir été utilisé.` : `The code is not valid: Code must be unused`} inputPinClickHandler={this.inputPinClickHandler} inputKeypressHandler={this.inputKeypressHandler} lang={langPreferance} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
                    </Fragment>
                )

            case 6: 
                return(
                    <Fragment>
                        <PinForm values={values} nextStep={this.handleSubmitSku} handleChange={this.handleChange} errorMessage={langPreferance === 'fr-CA' ? 'Code non trouvé dans notre base de données.' : 'The code you have entered was not found'} inputPinClickHandler={this.inputPinClickHandler} inputKeypressHandler={this.inputKeypressHandler} lang={langPreferance} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
                    </Fragment>
                )

            case 7:
                return(
                    <Fragment>
                        <PinForm values={values} nextStep={this.handleSubmitSku} handleChange={this.handleChange} errorMessage={langPreferance === 'fr-CA' ? 'Code erroné.' : 'The code you have entered is incorrect'} inputPinClickHandler={this.inputPinClickHandler} inputKeypressHandler={this.inputKeypressHandler} lang={langPreferance} />
                        <Recaptcha ref={ el => {this.recaptcha = el} } sitekey={RECAPTCHA_SITE_KEY} verifyCallback={this.verifyCallback} />
                    </Fragment>
                )

            case 8:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} invalidEntry={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )

            case 9:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} tooManyReqIp={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )

            case 10:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} tooManyReqEmail={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )

            case 11:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} internalError={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )

            case 12:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} dbError={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )

            case 13:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} badReq={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )
            
            case 14:
                return(
                    <Fragment>
                        <InfoForm values={values} errorMessages={errorMessages} prevState={this.prevState} handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} handleSubmitInfo={this.handleSubmitInfo} validateForm={this.validateForm} captchaError={true} closeWarningfromInfoForm={this.closeWarningfromInfoForm} loading={loading} lang={langPreferance} />
                    </Fragment>
                )
        }
    }
}

export default withRouter(EnterContest);