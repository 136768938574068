import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { TweenMax, Power3 } from 'gsap';
// import InjectIntl from 'react-intl-inject';
import ReactGA from 'react-ga';

//import css
import '../../styles/pages/_prize-details.scss';

//language file
import { translate } from '../../i18n/translate';

import { getContestState } from '../../util';

import prizeImage1_en from '../../images/prizes_en.png';
import prizeImage1_fr from '../../images/prizes_fr.png';
import prizeImage2_en from '../../images/cooler_en.png';
import prizeImage2_fr from '../../images/cooler_fr.png';

class PrizeDetails extends Component{

    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        //GSAP animation for the heading elements
        const { heading, prize1, prize1desc, prize2, prize2desc } = this;
        TweenMax.staggerFrom([heading, prize1, prize1desc, prize2, prize2desc], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut
        },.2)
    }

    render(){
        const langPreferance = this.props.lang;
        const contestState = getContestState();
        return(
            <Fragment>
                <div className="main-wrapper prize-details">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="page-heading">
                                        <h1 ref={el =>{this.heading = el}}>{translate('prizeDetails')}</h1>
                                    </div>
                                    <div className="row prize-details__text-section grand-prize">
                                        <div className="col-md-6 img">
                                            <img src={langPreferance === 'fr-CA' ? prizeImage1_fr : prizeImage1_en} ref={el => {this.prize1 = el}} alt="Level 6 iSUPS. Generic Mountain bikes / Generic Cheque and gas card " />
                                        </div>
                                        <div className="col-md-6" ref={el => {this.prize1desc = el}}>
                                            <h2>{translate('prizeDetails-heading1')}</h2>
                                            <div>
                                                <h4>{translate('prizeDetails-prod1heading')}</h4>
                                               <p>{translate('prizeDetails-prod1', {br : <br />})}
                                               </p> 
                                            </div>
                                            <div>
                                                <h4>{translate('prizeDetails-prod2heading')}</h4> 
                                                <p>{translate('prizeDetails-prod2', {br : <br />})}</p>
                                            </div>
                                            <div>
                                                <h4>{translate('prizeDetails-prod3heading')}</h4>
                                                <p>{translate('prizeDetails-prod3', {br : <br />})}</p>
                                            </div>
                                            <div>
                                                <h4>{translate('prizeDetails-prod4heading')}</h4>
                                                <p>{translate('prizeDetails-prod4', {br : <br />})}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row prize-details__text-section">
                                        <div className="col-md-6" ref={el => {this.prize2desc = el}}>
                                            <h2>{translate('prizeDetails-heading2')}</h2>
                                            
                                            <div>
                                               <h4>{translate('prizeDetails-subheading2')}</h4>
                                            <p>{translate('prizeDetails-body2', {br : <br />})}</p>
                                            <small>{translate('prizeDetails-legal')}</small> 
                                            </div>
                                        </div>
                                        <div className="col-md-6 img">
                                            <img src={langPreferance === 'fr-CA' ? prizeImage2_fr : prizeImage2_en } 
                                                ref={el => {this.prize2 = el}} 
                                                alt="PC Game Passes" 
                                                className="img-fluid" />
                                        </div>
                                    </div>
                                    { contestState===0 && <div className="text-center">
                                            <NavLink exact to="/enter-contest" className="enter-btn">
                                                {translate('enterNow')}
                                            </NavLink> 
                                        </div> }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default PrizeDetails;