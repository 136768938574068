import React, { Component } from 'react';
import { TextField, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
//language file
import { translate } from '../../i18n/translate';



class AddressForm extends Component{

    state = {
        error: {},
        address1: '',
        address2: '',
        city: '',
        prov: '',
        postal: '',
        complete: false,
        valid: false
    }

    componentDidMount(){
        // const form = this.form;

        // gsap.from(form, .6, {
        //     opacity:1,
        //     x: -300,
        //     ease: Power3.easeOut
        // })
    }

    checkAnswer = (e) => {
        e.preventDefault();
        
        if (!this.state.complete){
            return;
        }

        var error = {};

        // validate.
        let valid = true;
        if (this.state.address1==='') { valid = false; error.address1 = true; }
        if (this.state.city==='') { valid = false; error.city = true; }
        if (this.state.prov==='') { valid = false; error.prov = true; }
        if (this.state.postal==='') { valid = false; error.postal = true; }



        if (valid){
            this.props.onSubmit(this.state);
        } else {
            this.setState( {error} );
        }

    }

    handleChange = input => e => {
        this.setState({
            [input] : e.target.value
        })

        if (this.state.address1!=='' && this.state.city!=='' && this.state.prov!=='' && this.state.postal!==''){
            this.setState({complete:true});
        }
    }

    render(){

        const { address1, address2, city, prov, postal, error, complete } = this.state;
        // const [age, setAge] = useState('');

        return(
            <form onSubmit={this.checkAnswer} ref={el => this.form = el}>

                <TextField className={error.address1 ? 'error' : ''} variant="filled" label={translate('address1')} defaultValue={address1} onChange={this.handleChange('address1')} type="text" required />
                <TextField className={error.address2 ? 'error' : ''} variant="filled" label={translate('address2')} defaultValue={address2} onChange={this.handleChange('address2')} type="text" />
                <TextField className={error.city ? 'error' : ''} variant="filled" label={translate('city')} defaultValue={city} onChange={this.handleChange('city')} type="text" required />

                <FormControl variant="filled" fullWidth className={error.prov ? 'error' : ''} >
                    <InputLabel id="select-label">{translate('winningPage.field4')}</InputLabel>
                    <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={prov}
                    label={translate('winningPage.field4')}
                    onChange={this.handleChange('prov')}
                    required
                    >
                    <MenuItem value={"Ontario"}>Ontario</MenuItem>
                    <MenuItem value={'Alberta'}>Alberta</MenuItem>
                    <MenuItem value={'British Columbia'}>British Columbia</MenuItem>
                    <MenuItem value={'Manitoba'}>Manitoba</MenuItem>
                    <MenuItem value={'New Brunswick'}>New Brunswick</MenuItem>
                    <MenuItem value={'Newfoundland and Labrador'}>Newfoundland and Labrador</MenuItem>
                    <MenuItem value={'Northwest Territories'}>Northwest Territories</MenuItem>
                    <MenuItem value={'Nova Scotia'}>Nova Scotia</MenuItem>
                    <MenuItem value={'Nunavut'}>Nunavut</MenuItem>
                    <MenuItem value={'Prince Edward Island'}>Prince Edward Island</MenuItem>
                    <MenuItem value={'Quebec'}>Quebec</MenuItem>
                    <MenuItem value={'Saskatchewan'}>Saskatchewan</MenuItem>
                    <MenuItem value={'Yukon'}>Yukon</MenuItem>
                    </Select>
                </FormControl>
            
                <TextField className={error.postal ? 'error' : ''} variant="filled" label={translate('postal')} defaultValue={postal} onChange={this.handleChange('postal')} type="text" required />
                

                <button type="submit" className="pin-form__submit-btn green-btn" onClick={this.checkAnswer} disabled={!complete} >
                    {translate('winningPage.cta')}
                </button>
            </form>
        )
    }
}

export default AddressForm;