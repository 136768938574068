import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import { LOCALES } from '../../i18n'; //language files
import '../../styles/pages/_homepage.scss';
import Slider from "react-slick";

//import image
import { translate } from '../../i18n/translate';
import packshot from '../../../src/images/packages.png';
import step2 from '../../../src/images/barcode.en.png';
import step2fr from '../../../src/images/barcode.fr.png';
import step3 from '../../../src/images/prizes.png';
import caddy from '../../images/cooler.png';
import caddyfr from '../../images/cooler-2-fr.png';
import prod1 from '../../images/smores.png';
import prod2 from '../../images/pecan.png';
import prod3 from '../../images/brownie.png';

const prod1url_en = 'https://www.snackworks.ca/en/recipe/pbj-ritz-smores-206344.aspx';
const prod1url_fr = 'https://www.snackworks.ca/fr-ca/recette/smores-ritz-au-beurre-darachide-206452.aspx';
const prod2url_en = 'https://www.snackworks.ca/en/recipe/caramel-pecan-chips-ahoy-smores-206343.aspx';
const prod2url_fr = 'https://www.snackworks.ca/fr-ca/recette/smores-chips-ahoy-au-caramel-206451.aspx';
const prod3url_en = 'https://www.snackworks.ca/en/recipe/cadbury-smore-brownies-130481.aspx';
const prod3url_fr = 'https://www.snackworks.ca/fr-ca/recette/brownies-smore-cadbury-130482.aspx';


class Homepage extends Component {

    state = {
        isHover : false
    }

    

    componentDidMount(){
        
        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        //GSAP animation for the heading elements
        const { headingTwo, headingThree, headingFour, headingFive, bodyText } = this;
        gsap.from([ headingTwo, headingThree, headingFour, headingFive, bodyText, '.ckey'], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .1
        },.1)

    }

    componentWillUnmount(){
        
    }

    render(){
        const langPreferance = this.props.lang;
        const settings2 = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        const prod1url = langPreferance==='fr-CA' ? prod1url_fr : prod1url_en;
        const prod2url = langPreferance==='fr-CA' ? prod2url_fr : prod2url_en;
        const prod3url = langPreferance==='fr-CA' ? prod3url_fr : prod3url_en;
        const caddyimg = langPreferance==='fr-CA' ? caddyfr : caddy;
        return (
            <Fragment>
                <div className="main-wrapper">
                    <div className="container home">
                        <div className="row bg-home">
                                <div className="col home-text-container text-center"> 
                                <div className="heading-text">
                      
                                    <h1 className="prize-money" ref={el => {this.headingTwo = el}}>
                                        <span>{translate('homepage.prize1', {sup:chunks=><sup>{chunks}</sup>})}</span>
                                        <span>{translate('homepage.prize')}</span>
                                    </h1>
                                </div>
                                <NavLink exact className="enter-btn" activeClassName="selectedLink" to='/enter-contest' tabIndex="0">
                                    {translate('enterCta')}
                                </NavLink>
                                <div className="learn-more-container">
                                    <a href="#howtoenter">
                                        {translate('homepage.cta')}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="snack-caddy">
                            <img className="caddy" src={caddyimg} alt="Cooler full of snack product" />

                            <div>
                                <h2 className="plus-cash" ref={el => {this.headingFour = el}}>
                                    <span>{translate('homepage.pluscash')}</span>
                                    <span className='text-xxl'>{translate('homepage.pluscash2')}</span>
                                </h2>
                                <span>{translate('homepage.pluscash3')}</span>
                                <NavLink exact className="enter-btn" activeClassName="selectedLink" to='/enter-contest' tabIndex="0">
                                {translate('enterCta')}
                                </NavLink>
                            </div>
                        </div>
                        
                        <div className="row" id="howtoenter">
                            <div className="col-md-12">
                                <h2  className='text-center my-8'>{translate('homepage.howtoenter')}</h2>
                            </div>
                            <div className='steps col-sm-8 col-md-6'>
                                <div className="">
                                    <img src={packshot} className="packshot img-fluid" alt="Inset visual of products lineup: CA!, Crispers, Cadbury, Ritz" />
                                    <p>{translate('homepage.step1')}</p>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div className="row" id="summeroffers">
                            <div className="col-md-12">
                                <h2 className='text-center my-8'>{translate('homepage.offerhead')}</h2>
                            </div>
                            <div className='noMobile grid'>
                                <div>
                                    <a href={prod1url} target="_blank">
                                        <img src={prod1} className="offer" alt="Ritz s’more beauty shot" /></a>
                                    <a href={prod1url} target="_blank">
                                        <h3>{translate('homepage.prod1')}</h3>
                                    </a> 
                                    <a href={prod1url} target="_blank" className='recipe-link'>
                                        {translate('homepage.offercta')}
                                    </a>
                                </div>
                                <div>
                                    <a href={prod2url} target="_blank">
                                       <img src={prod2} className="offer" alt="CA! S’mores" /> 
                                    </a>
                                    <a href={prod2url} target="_blank">
                                        <h3>{translate('homepage.prod2')}</h3> 
                                    </a>
                                    <a href={prod2url} target="_blank" className='recipe-link'>
                                        {translate('homepage.offercta')} 
                                    </a>
                                </div>
                                <div>
                                    <a href={prod3url} target="_blank">
                                        <img src={prod3} className="offer" alt="Cadbury S’more Brownies" />
                                    </a>
                                    <a href={prod3url} target="_blank">
                                        <h3>{translate('homepage.prod3')}</h3> 
                                    </a>
                                    <a href={prod3url} target="_blank" className='recipe-link'>
                                        {translate('homepage.offercta')} 
                                    </a>
                                </div>
                            </div>
                            <div className='mobileOnly'>
                                <Slider {...settings2}>
                                    <div>
                                        <a href={prod1url} target="_blank">
                                            <img src={prod1} className="offer" alt="Ritz s’more beauty shot" /></a>
                                        <a href={prod1url} target="_blank">
                                            <h3>{translate('homepage.prod1')}</h3>
                                        </a> 
                                        <a href={prod1url} target="_blank" className='recipe-link'>
                                            {translate('homepage.offercta')}
                                        </a>
                                    </div>
                                    <div>
                                    <a href={prod2url} target="_blank">
                                       <img src={prod2} className="offer" alt="CA! S’mores" /> 
                                    </a>
                                    <a href={prod2url} target="_blank">
                                        <h3>{translate('homepage.prod2')}</h3> 
                                    </a>
                                    <a href={prod2url} target="_blank" className='recipe-link'>
                                        {translate('homepage.offercta')} 
                                    </a>
                                </div>
                                <div>
                                    <a href={prod3url} target="_blank">
                                        <img src={prod3} className="offer" alt="Cadbury S’more Brownies" />
                                    </a>
                                    <a href={prod3url} target="_blank">
                                        <h3>{translate('homepage.prod3')}</h3> 
                                    </a>
                                    <a href={prod3url} target="_blank" className='recipe-link'>
                                        {translate('homepage.offercta')} 
                                    </a>
                                </div>
                                </Slider>
                            </div>
                        </div>

                       
                    </div>
                    <div className="container disclaimer-wrap">
                        <div className="row">
                            <div className="col-md-12 disclaimer">
                                <small>{translate('legal',{link:<NavLink exact to="/">{translate('legal.link')}</NavLink>})}</small>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }
}

export default Homepage;